.links {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30vh 0;

  a {
    display: block;
    width: 200px;
    padding: 12px;
    text-align: center;
    text-decoration: none;
    font-size: 24px;
    border-radius: 4px;
    margin: 0 8px;

    &:nth-child(1) {
      background-color: chocolate;
      color: #ffffff;
    }
    &:nth-child(2) {
      background-color: aquamarine;
      color: #000000;
    }
  }
}