.layout {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  & * {
    box-sizing: border-box;
  }
}

html, body {
  padding: 0;
  margin: 0;
}