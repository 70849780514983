.button_add {
  position: absolute;
  z-index: 100;
  top: 8px;
  left: calc(50% - 100px);
  width: 200px;
  padding: 8px;
  background-color: blue;
  border: none;
  font-size: 22px;
  color: white;
  cursor: pointer;
}

.poly_plot {
  position: absolute;
  z-index: 100;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border: 4px;
  max-width: 260px;
  padding: 16px;

  &_input{
    padding: 4px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    label{
      width: 80px;
    }

    input, select{
      width: calc(100% - 80px);
      border: 1px solid #000000;
      border-radius: 4px;
      font-size: 14px;
      padding: 4px;
    }
  }

  &_selectWrap{
    width: calc(100% - 80px);
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      width: 28px;
      height: 28px;
    }

    select {
      width: calc(100% - 62px);
    }
  }
  &_buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_save {
      padding: 8px 12px;
      font-size: 12px;
      background-color: darkseagreen;
      border: none;
      border-radius: 4px;
    }

    &_remove {
      padding: 8px 12px;
      font-size: 12px;
      background-color: red;
      border: none;
      border-radius: 4px;
    }
  }

  &_slider {
    padding: 8px 0;
  }
}

.field-images {
  display: flex;
  align-items: center;
  justify-content: space-between;

  &_label {
   margin-right: 15px;
  }

  //&_button {
  //  padding: 0;
  //  border: 0;
  //}

  &_preview {
    width: 20px;
    height: 20px;
    object-fit: cover;
  }
}